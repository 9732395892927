import cloneDeep from 'clone-deep'
import { LayoutLoading } from 'components/LayoutLoading'
import { useEffect, useState } from 'react'
import { getServicingNotes, postServicingNotes } from 'services'
import { TextArea } from 'stories/components'
import { RenderInput } from 'utils/RenderInput'
import { setLoanNumber } from 'utils/setLoanNumber'

const sections = ['Default Information', 'Additional Information']
export const ServicingNote = () => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState<any>({})
  const [order, setOrder] = useState<Array<string>>([])
  const [additionalOrder, setAdditionalOrder] = useState<Array<string>>([])
  const [comment, setComment] = useState('')

  useEffect(() => {
    setLoading(true)
    setLoanNumber()
    getServicingNotes()
      .then((res: any) => {
        if (res.success) {
          setData(res.info)
          setComment(res.comment)
          setOrder(res.order)
          setAdditionalOrder(res.additionalOrder)
        }
      })
      .finally(() => setLoading(false))
  }, [])

  const onChange = async (key: string, value: string) => {
    const temp = cloneDeep(data)
    temp[key].value = value
    setData(temp)
  }

  const onBlur = async (key: string) => {
    const value = data[key].value
    setLoading(true)
    await postServicingNotes({
      key,
      value,
    })
    setLoading(false)
  }

  const changeComment = (value: string) => {
    setComment(value)
  }

  const onBlurComment = async () => {
    setLoading(true)
    await postServicingNotes({
      key: 'comment',
      value: comment,
    })
    setLoading(false)
  }

  return (
    <div className="servicing-note-container">
      <LayoutLoading show={loading} />
      <div className="w-full">
        <div className="grid grid-cols-3 gap-2">
          {[order, additionalOrder].map((_order, index: number) => {
            return (
              <>
                <div className="col-span-full">
                  <RenderInput
                    input={{
                      inputType: 'section',
                      title: sections[index],
                    }}
                    Key={`section-${sections[index]}`}
                    onChange={() => {}}
                  />
                </div>
                {_order.map((key, index) => {
                  if (!data[key]) return null
                  let { note, value, type } = data[key]
                  if (value === undefined || value === null) value = ''
                  return (
                    <div key={index} className="col-span-1">
                      <RenderInput
                        input={{
                          inputType: 'text',
                          type,
                          title: note,
                          value,
                        }}
                        Key={key}
                        onChange={(key: string, value: any) => onChange(key, value)}
                        onBlur={(key: string) => onBlur(key)}
                      />
                    </div>
                  )
                })}
              </>
            )
          })}
        </div>
        <div className="mt-4">
          <TextArea
            title="Comment"
            rows={4}
            value={comment}
            onChange={(v) => changeComment(v)}
            onBlur={onBlurComment}
          />
        </div>
      </div>
    </div>
  )
}
